import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';


import { useTranslation } from 'react-i18next';
function Termsofuse() {

    const { i18n, t } = useTranslation();


    useEffect(() => {
        if (!i18n.language) {
            i18n.changeLanguage('en'); // Set default language to English
        }
    }, [i18n]);


    return (
        <Container>
            <Row>
                <Container>
                    <h1>Terms of Use</h1>

                    <p>Welcome to Pets Home App (the "App"). These Terms of Use ("Terms") govern your use of our App and services ("Services"). By accessing or using the App, you agree to comply with and be bound by these Terms. If you do not agree with these Terms, please do not use the App.</p>

                    <h2>1. Acceptance of Terms</h2>

                    <p>By downloading, accessing, or using the App, you acknowledge that you have read, understood, and agree to be bound by these Terms and any applicable rules applied by Apple Inc. ("App Store Rules") and Google Inc. ("Play Store Rules") with respect to the use of their App Store and Play Store respectively.</p>

                    <h2>2. Platform Description</h2>

                    <p>Pets Home App is a self-service platform that connects pet owners ("Sellers") with potential buyers ("Buyers") locally and internationally. We provide tools and features to facilitate the listing and discovery of pets for sale or adoption.</p>

                    <h2>3. User Responsibilities</h2>

                    <h3>3.1. Due Diligence</h3>

                    <p>We are not responsible for any fraudulent activities or misconduct that occurs between users of our platform. The App provides connectivity between Sellers and Buyers, but we do not participate in or facilitate financial transactions between users. All deals and transactions are conducted directly between Sellers and Buyers at their own risk.</p>

                    <p>It is the sole responsibility of the users to conduct appropriate due diligence before engaging in any transaction. This includes verifying the legitimacy and authenticity of other users and listings.</p>

                    <h3>3.2. Compliance with Laws</h3>

                    <p>Users agree to comply with all applicable local, state, national, and international laws and regulations in connection with their use of the App.</p>

                    <h3>3.3. Account and Content Management</h3>

                    <p>Users are responsible for maintaining the confidentiality of their account information and for all activities that occur under their account.</p>

                    <p>If a user decides to delete their account or cancel their subscription, it is their responsibility to delete all their advertisements before doing so. Failure to delete ads may result in continued display of the user's content on the App.</p>

                    <h2>4. Prohibited Activities</h2>

                    <p>Users are prohibited from engaging in the following activities:</p>

                    <ul>
                        <li>Posting objectionable, offensive, or illegal content.</li>
                        <li>Harassing, threatening, or defrauding other users.</li>
                        <li>Engaging in spamming or unsolicited advertising.</li>
                        <li>Attempting to interfere with or disrupt the App's functionality.</li>
                        <li>Violating any intellectual property rights.</li>
                    </ul>

                    <h2>5. Limitation of Liability</h2>

                    <p><strong>Disclaimer of Warranties:</strong> The App is provided on an "AS IS" and "AS AVAILABLE" basis. We make no warranties or representations, express or implied, about the accuracy, reliability, suitability, or availability of the App or the information, products, or services contained on the App for any purpose.</p>

                    <p><strong>No Liability for Transactions:</strong> We are not liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to any transactions conducted between users of the App.</p>

                    <p><strong>No Financial Transactions:</strong> The App does not facilitate or process financial transactions between users. All payments and transactions are conducted independently between Sellers and Buyers.</p>

                    <h3>6 A. Ad Purchase and Usage Terms</h3>

                    <h4>6 A 1. Ad Purchase and Consumption</h4>
                    <ul>
                        <li>Users may purchase a single post (ad) as a consumable product. Once the ad is published, the purchase is considered consumed and cannot be refunded.</li>
                    </ul>

                    <h4>6 A 2. Ad Content and Updates</h4>
                    <ul>
                        <li>After publishing an ad, <strong>users cannot change or update images</strong> associated with the post. However, users may edit the text content of the ad (e.g., descriptions or details) as needed, at no extra charge.</li>
                    </ul>

                    <h4>6 A 3. Ad Boosting</h4>
                    <ul>
                        <li>Users have the option to <strong>boost their ad visibility multiple times</strong> after publishing. Each boost will be treated as a separate transaction and is non-refundable once completed.</li>
                    </ul>

                    <h4>6 A 4. Ad Deletion</h4>
                    <ul>
                        <li>Once a user deletes an ad, the action is <strong>permanent and irreversible</strong>. All data related to the ad, including any boosts applied, will be lost, and the user will need to purchase a new ad if they wish to create a new post.</li>
                    </ul>

                    <h4>6 A 5. Liability and Responsibility</h4>
                    <ul>
                        <li>Pets Home app and its operators are <strong>not liable</strong> for any consequences resulting from the posting or deletion of ads. Users are responsible for ensuring the accuracy and appropriateness of their ad content before publishing.</li>
                        <li>The app reserves the right to remove ads that violate its terms of service, including but not limited to inappropriate content, misuse, or legal violations. In such cases, <strong>no refunds</strong> will be issued.</li>
                    </ul>

                    <h4>6 A 6. Refunds and Disputes</h4>
                    <ul>
                        <li>Once an ad is published, the purchase is considered consumed, and no refunds will be granted for any reason, including accidental purchases, errors in content, or failure to boost.</li>
                        <li>Any disputes regarding ad purchases or usage must be submitted in writing within 14 days of the purchase date, and the final decision rests with the Pets Home app team.</li>
                    </ul>

                    <h4>6 A 7. Changes to Terms</h4>
                    <ul>
                        <li>Pets Home app reserves the right to modify these terms at any time. Users will be notified of any significant changes, and continued use of the app constitutes acceptance of any updated terms.</li>
                    </ul>

                    <h2>6 B. Subscription and Fees</h2>

                    <p>The App may offer subscription plans that provide additional features and benefits. Users agree to the following terms regarding subscriptions:</p>

                    <h3>6 B .1. Free Trial Period</h3>

                    <p>During the free trial period, users can access premium features such as ad postings and boosts at no cost. We strive to provide support during this period, but due to resource limitations, we may not be able to respond to all customer support inquiries.</p>

                    <h3>6 B .2. Subscription Plans</h3>

                    <p>After the free trial period, a subscription is required to continue accessing premium features. Subscription fees are non-refundable except where required by law.</p>

                    <p>Users are responsible for managing their subscriptions, including cancellation before the renewal date if they do not wish to continue. Failure to cancel in time may result in automatic renewal and charges.</p>

                    <h2>7. Support Limitations</h2>

                    <p>Pets Home App is a self-service platform. While we aim to provide support to our users, we may not be able to respond to all customer support emails or inquiries, especially during the free trial offering.</p>

                    <p>Users are encouraged to refer to our FAQs, help guides, and community forums for assistance.</p>

                    <h2>8. Intellectual Property Rights</h2>

                    <p>All content, trademarks, and data on the App, including but not limited to text, graphics, logos, icons, designs, and images, are the property of Pets Home App or its content suppliers and are protected by international intellectual property laws.</p>

                    <p>Users may not reproduce, distribute, or create derivative works from any of the content on the App without our express written consent.</p>

                    <h2>9. Privacy Policy</h2>

                    <p>Your use of the App is also governed by our <a href="/privacy-policy">Privacy Policy</a>, which is incorporated into these Terms by reference. By using the App, you consent to the collection and use of your information as outlined in the Privacy Policy.</p>

                    <h2>10. Communications</h2>

                    <p>Upon registering with Pets Home App, you agree to receive essential communications directly to your email. This includes a welcome email upon registration and notifications for new messages, particularly when your in-app notifications are disabled. These communications are part of our commitment to ensure you stay informed and connected regarding your account.</p>

                    <h2>11. Termination of Access</h2>

                    <p>We reserve the right to restrict, suspend, or terminate your access to the App or any part thereof at any time without notice if we believe you are in violation of these Terms or any applicable law, or for any other reason at our discretion.</p>

                    <h2>12. Indemnification</h2>

                    <p>You agree to indemnify, defend, and hold harmless Pets Home App, its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, losses, liabilities, expenses, damages, and costs, including reasonable attorneys' fees, arising from or related to your use of the App or any violation of these Terms.</p>

                    <h2>13. Disclaimers</h2>

                    <p>We have zero tolerance for objectionable content or abusive users using the App. Abuse includes any threats, dishonest behavior, or unwanted actions toward others. We reserve the right to monitor App usage and content and to warn or deactivate user accounts at our discretion.</p>

                    <p>We are not responsible if the information available on the App is not accurate or complete. Any reliance on the material is at your own risk. It is your responsibility to monitor changes to the material and information contained on the App.</p>

                    <h2>14. Limitation of Liability for Viruses</h2>

                    <p>We are not liable for any loss or damage caused by viruses or other technologically harmful material that may infect your device, data, or other proprietary material due to your use of the App or downloading of any content.</p>

                    <h2>15. Third-Party Links</h2>

                    <p>The App may contain links to third-party websites or services that are not owned or controlled by Pets Home App. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.</p>

                    <h2>16. Changes to Terms</h2>

                    <p>We reserve the right to modify or replace these Terms at any time. We will notify users of any significant changes by posting the new Terms on this page. Your continued use of the App after any such changes constitutes your acceptance of the new Terms.</p>

                    <h2>17. Governing Law</h2>

                    <p>These Terms are governed by and construed in accordance with the laws of the United Kingdom, without regard to its conflict of law provisions. You agree to submit to the exclusive jurisdiction of the courts located within the United Kingdom to resolve any legal matter arising from these Terms.</p>

                    <h2>18. Severability</h2>

                    <p>If any provision of these Terms is found to be invalid or unenforceable by a court, the remaining provisions will remain in full force and effect.</p>

                    <h2>19. Entire Agreement</h2>

                    <p>These Terms constitute the entire agreement between you and Pets Home App regarding the use of the App and supersede any prior agreements.</p>

                    <h2>20. Contact Us</h2>

                    <p>If you have any questions about these Terms, please contact us at <a href="mailto:info@petshome.app">info@petshome.app</a>.</p>

                </Container>
            </Row>
        </Container>
    );
}

export default Termsofuse;
