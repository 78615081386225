import React, { useState, useEffect } from 'react';
import { Nav, Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Card } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import './FindPets.css'; // Import CSS for responsive layout
import { useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet-async';


function FindPets() {
    const [petAds, setPetAds] = useState([]);
    const { lang } = useParams();
    const { t } = useTranslation();
    // Fetch the pet ads on component mount
    useEffect(() => {
        fetch('https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/all')
            .then((response) => response.json())
            .then((data) => setPetAds(data))
            .catch((error) => console.error('Error fetching pet listings:', error));
    }, []);

    // Generate each advert card
    const generateAdvertCard = (ad, key) => {
        const { title, pet_breed, main_image, author_country_code, asking_price, description } = ad;
        const flagUrl = `https://flagcdn.com/24x18/${author_country_code.toLowerCase()}.png`;
        const petBreed = pet_breed || 'Mixed Breed';
        const slug = `${pet_breed || 'pet'}-${title}`
            .toLowerCase()
            .normalize('NFD') // Normalize accents
            .replace(/[\u0300-\u036f]/g, '') // Remove accents
            .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
            .replace(/^-+|-+$/g, ''); // Trim hyphens from the start and end


        return (
            <Card key={key} className="pet-card">
                <div style={{ position: 'relative' }}>
                    {main_image && (
                        <Card.Img
                            variant="top"
                            src={main_image}
                            style={{ objectFit: 'cover', height: '200px' }}
                            alt={`Image of ${petBreed}`}
                        />
                    )}
                    <img
                        src={flagUrl}
                        alt={`Flag of ${author_country_code}`}
                        style={{
                            position: 'absolute',
                            top: '8px',
                            right: '8px',
                            width: '24px',
                            height: '18px',
                            borderRadius: '3px',
                            boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
                        }}
                    />
                </div>
                <Card.Body>
                    {/* Clickable Title */}
                    <Link to={`/${lang}/${slug}/${ad.id}`} className="card-title-link">
                        <Card.Title style={{ fontSize: '16px', fontWeight: 'bold', color: '#007bff' }}>{title}</Card.Title>
                    </Link>

                    <Card.Text style={{ fontSize: '14px' }}>
                        <strong>Breed:</strong> {petBreed}
                    </Card.Text>
                    <Card.Text
                        style={{
                            fontSize: '12px',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                        }}
                    >
                        {description}
                    </Card.Text>
                    <Card.Text style={{ fontSize: '14px' }}>
                        <strong>Rehome Fee:</strong> ${asking_price} <br />
                        <strong>Location :</strong> {author_country_code}
                    </Card.Text>
                </Card.Body>
            </Card>
        );
    };

    return (

        <>
            <Helmet>
                <title>{t('find.meta_title')}</title>
                <meta name="description" content={t('find.meta_description')} />
                <meta name="keywords" content={t('find.meta_keywords')} />
            </Helmet>



            <div className="FindPets">
                <h2 className="my-4">{t('find.h1')} </h2>
                <p className="text-muted">{t('find.description')}</p>

                <Container className="p-4">
                    <Row>
                        <Col md={12} className="p-4">
                            <h5 className="text-center font-weight-bold">{t('global.h5_dwonload')}</h5>
                            <div className="d-flex justify-content-center">
                                <a href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3" title="Download Pets Home App from Google Play Store">
                                    <img style={{ width: '180px', height: 'auto' }} src="/google-play-badge.svg" alt="Download Pets Home App on Google Play" />
                                </a>
                                <a href="https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer" title="Download Pets Home App from Apple App Store">
                                    <img style={{ width: '180px', height: 'auto' }} src="/app-store-badge.svg" alt="Download Pets Home App on App Store" />
                                </a>
                            </div>
                            <div className="d-flex justify-content-center">
                                {/* <small> {t('rated')} </small> */}
                                <div>
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                    <FontAwesomeIcon icon={faStar} color="gold" />
                                </div>
                            </div>
                            <div className="d-flex justify-content-center mt-4">
                                <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                                <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                            </div>
                            <p className="text-center mt-3 d-none d-md-block">Scan the QR codes below for quick access:</p>
                        </Col>
                    </Row>
                </Container>
                <hr />
                <div className="pet-listing">
                    {petAds.length > 0 ? (
                        petAds.map((ad, index) => generateAdvertCard(ad, index))
                    ) : (
                        <p>No pet listings available at the moment.</p>
                    )}
                </div>


            </div>
        </>

    );
}

export default FindPets;
