import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
const API_KEY = 'AIzaSyBFbJTDQKcKDllR5ShH_oyfN1yozLr79m8'; // Place your API key here
const PLAYLIST_ID = 'PLQ7AxwuM50g7VMr68TY4M4cu_m7k8YYgH'; // Place your Playlist ID here
const YOUTUBE_PLAYLIST_ITEMS_API = `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet,contentDetails,status&playlistId=${PLAYLIST_ID}&maxResults=50&key=${API_KEY}`;

function VideeoPage() {
    const [videos, setVideos] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedVideoId, setSelectedVideoId] = useState(null);
    const { t } = useTranslation();
    useEffect(() => {
        const fetchPlaylistVideos = async () => {
            try {
                const response = await fetch(YOUTUBE_PLAYLIST_ITEMS_API);
                const data = await response.json();
                setVideos(data.items);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        };

        fetchPlaylistVideos();
    }, []);

    const handleVideoSelect = (videoId) => {
        setSelectedVideoId(videoId);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <Helmet>
                <title>{t('video.meta_title')}</title>
                <meta name="description" content={t('video.meta_description')} />
                <meta name="keywords" content={t('video.meta_keywords')} />
            </Helmet>

            <React.Fragment>
                <Container className='mb-4'>
                    <Col md={12} className=' p-4'>

                        <h2 className="my-4">{t('video.h1')} </h2>
                        <p className="text-muted">{t('video.description')}</p>

                        <hr />
                    </Col>
                    <Col md={12} className=' p-4'>

                        <p className="">{t('global.experince')}</p>
                        <p className='text-center font-weight-bold text-uppercase'>{t('global.h5_dwonload')}</p>
                        <div className="d-flex justify-content-center">
                            <a style={{ width: '130px', height: 'auto' }} href="https://play.google.com/store/apps/details?id=com.app.petshome" target="_blank" rel="noopener noreferrer" className="mr-3"><img src="/google-play-badge.svg" alt="Google Play" /></a>
                            <a style={{ width: '130px', height: 'auto' }} href="https://apps.apple.com/us/app/pets-home-app/id1549828591?pt=581834&ct=desktop&mt=8" target="_blank" rel="noopener noreferrer"><img src="/app-store-badge.svg" alt="App Store" /></a>
                        </div>

                        <div className="d-flex justify-content-center">
                            <img style={{ width: '130px', height: 'auto' }} src="/android-adobe-express-qr-code.png" alt="Android QR Code" className="mr-3" />
                            <img style={{ width: '130px', height: 'auto' }} src="/ios-express-qr-code.png" alt="iOS QR Code" />
                        </div>
                        <p className="text-center mt-3">{t('global.qr_code_p')} </p>

                    </Col>
                    <Row>
                        {videos.map((video, index) => (
                            <Col key={index} md={4} className="mb-4">
                                <Card className="h-100" onClick={() => handleVideoSelect(video.contentDetails.videoId)}>
                                    <Card.Img variant="top" src={video.snippet.thumbnails.medium.url} style={{ borderRadius: '10px' }} />
                                    <Card.ImgOverlay className="d-flex justify-content-center align-items-center">
                                        <Button variant="primary">Play</Button>
                                    </Card.ImgOverlay>
                                    <Card.Body>
                                        <Card.Title>{video.snippet.title}</Card.Title>
                                        {/* Additional video details like views and likes can be fetched separately as they require another API call */}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>

                <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                    <Modal.Body>
                        <iframe
                            width="100%"
                            height="500px"
                            src={`https://www.youtube.com/embed/${selectedVideoId}`}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                        </iframe>
                    </Modal.Body>
                </Modal>
            </React.Fragment>
        </>
    );
}

export default VideeoPage;
