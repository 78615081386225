import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, Modal, Button } from 'react-bootstrap';



import { useTranslation } from 'react-i18next';
function PrivacyPolicy() {
    const { i18n, t } = useTranslation();


    useEffect(() => {
        if (!i18n.language) {
            i18n.changeLanguage('en'); // Set default language to English
        }
    }, [i18n]);

    return (
        <Container>
            <Row>
                <Container>

                    <h1>Privacy Policy for Pets Home App</h1>
                    <p>Your privacy is important to us. This Privacy Policy explains how Pets Home App collects, uses,
                        discloses, and safeguards your personal information when you use our mobile applications and
                        website.</p>

                    <h2>Information We Collect</h2>
                    <p>We may collect personal information such as your name, email address, location, and any other
                        information you voluntarily provide when registering or using our services.</p>

                    <h2>How We Use Your Information</h2>
                    <p>We use your information to provide and maintain our services, personalize your experience,
                        communicate with you, and improve our app.</p>


                    <h2>Disclosure of Your Information</h2>
                    <p>We may share your information with third-party service providers for the purpose of providing our
                        services to you. We may also disclose your information if required by law.</p>

                    <h2>Security of Your Information</h2>
                    <p>We implement reasonable security measures to protect your personal information. However, please
                        be aware that no security measures are perfect or impenetrable.</p>

                    <h2>Data Retention</h2>
                    <p>We retain your personal information only as long as necessary to fulfill the purposes outlined in
                        this policy, unless a longer retention period is required or permitted by law.</p>


                    <h2>Your Rights</h2>
                    <p>You have the right to access, correct, or delete your personal information at any time. Please
                        contact us to exercise these rights.</p>


                    <h2>Cookies and Tracking Technologies</h2>
                    <p>We use cookies to enhance your experience. You can choose to disable cookies through your browser
                        settings, but this may affect the functionality of the app.</p>

                    <h2>Third-Party Links</h2>
                    <p>Our app may contain links to external sites not operated by us. We are not responsible for their
                        content or privacy practices. We encourage you to review their privacy policies.</p>

                    <h2>Children's Privacy</h2>
                    <p>Our services are not intended for individuals under the age of 13. We do not knowingly collect
                        personal information from children under 13.</p>

                    <h2>Changes to This Privacy Policy</h2>
                    <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting
                        the new Privacy Policy on this page.</p>

                    <h2>Contact Us</h2>
                    <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a
                        href="mailto:info@petshome.app">info@petshome.app</a>.</p>


                    <p>By using our app, you consent to the terms of this Privacy Policy.</p>


                </Container>
            </Row>
        </Container>
    );
}

export default PrivacyPolicy;
