import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LetsPartner = () => {
    const { t } = useTranslation();

    return (
        <Container className="my-5">
            {/* Meta Tags for SEO */}
            <Helmet>
                <title>{t('partnership-opportunities.meta_title')}</title>
                <meta name="description" content={t('partnership-opportunities.meta_description')} />
                <meta name="keywords" content={t('partnership-opportunities.meta_keywords')} />
            </Helmet>


            {/* Introduction Section */}
            <Row className="mb-5">
                <Col className="text-center">
                    <h1>{t('partnership-opportunities.title')}</h1>
                    <p className="lead">{t('partnership-opportunities.introduction')}</p>
                </Col>
            </Row>

            {/* Partnership Opportunities Section */}
            <Row className="mb-5">
                <Col>
                    <h2>{t('partnership-opportunities.partnershipOpportunities')}</h2>
                    <ul className="list-unstyled">
                        <li><strong>{t('partnership-opportunities.backlinkExchange')}</strong></li>
                        <li><strong>{t('partnership-opportunities.advertising')}</strong></li>
                        <li><strong>{t('partnership-opportunities.sponsorships')}</strong></li>
                        <li><strong>{t('partnership-opportunities.productCollaborations')}</strong></li>
                    </ul>
                </Col>

            </Row>

            {/* Investment Opportunities Section */}
            <Row className="mb-5">
                <Col>
                    <h2>{t('partnership-opportunities.investmentOpportunities')}</h2>
                    <p>{t('partnership-opportunities.investmentDetails')}</p>
                </Col>
            </Row>

            {/* Custom Solutions Section */}
            <Row className="mb-5">
                <Col >
                    <h2>{t('partnership-opportunities.customSolutions')}</h2>
                    <p>{t('partnership-opportunities.customSolutionsDetails')}</p>
                </Col>
            </Row>

            {/* Contact Section */}
            <Row className="mb-5 text-center">
                <Col>
                    <h2>{t('partnership-opportunities.contactUs')}</h2>
                    <p>{t('partnership-opportunities.contactDetails')}</p>
                </Col>
            </Row>
        </Container>
    );
};

export default LetsPartner;
