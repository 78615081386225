import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Card, Spinner, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import './PetDetail.css'; // Import CSS for responsive layout
function PetDetail() {
    const { id, slug } = useParams(); // Get the ID and slug from the URL params
    const [pet, setPet] = useState(null); // State to hold the pet details
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    const { t } = useTranslation();
    const location = useLocation();

    useEffect(() => {
        const fetchPetDetails = async () => {
            try {
                const response = await fetch(`https://us-central1-petshome-da28b.cloudfunctions.net/api/public/pets/ad?id=${id}`);
                const data = await response.json();

                if (response.ok) {
                    setPet(data);
                } else {
                    setError(data.error || 'Error fetching pet details');
                }
            } catch (err) {
                setError('Unable to fetch pet details. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchPetDetails();
    }, [id]);

    if (loading) {
        return <Spinner animation="border" role="status"><span className="sr-only">Loading...</span></Spinner>;
    }

    if (error) {
        return <Alert variant="danger">{error}</Alert>;
    }

    if (!pet) {
        return <Alert variant="warning">No pet details available.</Alert>;
    }

    const { title, pet_breed, main_image, author_country_code, asking_price, description } = pet;

    const pageTitle = `${title} | PetsHome`; // Create the dynamic page title

    return (
        <>
            <Helmet>
                <link rel="canonical" href={`https://www.petshome.app${location.pathname}${location.search}`} />
                <title>{pageTitle}</title>
                <meta name="description" content={description || 'No description available.'} />
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description || 'No description available.'} />
                {main_image && <meta property="og:image" content={main_image} />}
                <meta property="og:type" content="article" />
                <meta property="og:url" content={`https://petshome.app${location.pathname}`} />
                {/* Twitter Card Meta Tags */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description || 'No description available.'} />
                {main_image && <meta name="twitter:image" content={main_image} />}
            </Helmet>

            <div className="PetDetail">
                <Card className="pet-card" style={{ margin: '20px auto' }}>
                    {main_image && (
                        <Card.Img
                            variant="top"
                            src={main_image}
                            alt={`Image of ${pet_breed}`}
                            className="pet-image"
                        />
                    )}
                    <Card.Body>
                        <Card.Title>{title}</Card.Title>
                        <Card.Text>
                            <strong>Breed:</strong> {pet_breed || 'Unknown'}
                        </Card.Text>
                        <Card.Text>
                            <strong>Description:</strong> {description || 'No description available.'}
                        </Card.Text>
                        <Card.Text>
                            <strong>Rehome Fee:</strong> ${asking_price}
                        </Card.Text>
                        <Card.Text>
                            <strong>Country:</strong> {author_country_code}
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        </>
    );
}

export default PetDetail;
