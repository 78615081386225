import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
    const { t } = useTranslation();

    return (
        <>
            <Helmet>
                <title>About Us | Pets Home App</title>
                <meta name="description" content="Learn about the story behind Pets Home App, a platform designed to help people find, adopt, and rehome pets locally with the use of AI technology. We offer free listings to pet charities worldwide." />
                <meta name="keywords" content="pet adoption, rehoming pets, pet breed scanner, pet social platform, AI pet ads, local pet adoption, free pet charity listings, instant notifications" />
                <meta property="og:title" content="About Us | Pets Home App" />
                <meta property="og:description" content="Discover the story behind Pets Home App and how we connect people to pets using AI technology and a user-friendly platform." />
                <meta property="og:url" content="https://www.petshome.app/about-us" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Container>
                <Row>
                    <Col>
                        <h2 className="my-4">{t('aboutUs.title')}</h2>
                        <p>{t('aboutUs.ourStory')}</p>
                        <h2>{t('aboutUs.featuresTitle')}</h2>
                        <ul>
                            <li>{t('aboutUs.feature1')}</li>
                            <li>{t('aboutUs.feature2')}</li>
                            <li>{t('aboutUs.feature3')}</li>
                            <li>{t('aboutUs.feature4')}</li>
                        </ul>
                        <h2>{t('aboutUs.charitySupportTitle')}</h2>
                        <p>{t('aboutUs.charitySupportDescription')}</p>
                    </Col>
                </Row>

                {/* Contact Section */}
                <Row className="mb-5 text-center">
                    <Col>
                        <h2>{t('partnership-opportunities.contactUs')}</h2>
                        <p>{t('partnership-opportunities.contactDetails')}</p>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default AboutUs;
