import React, { useEffect } from 'react';

import { Routes, Route, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Navigation from './components/Navbar';
import FindPets from './pages/FindPets';
import PetDetail from './pages/PetDetail';
import Home from './pages/Home';
import BreedDetector from './pages/BreedDetector';
import PetAdvisor from './pages/PetAdvisor';
import VideoPage from './pages/videos';
import Termsofuse from './pages/Termsofuse';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CancelSubscription from './pages/cancelSubscription';
import Footer from './components/Footer'; // Import the Footer component
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootswatch/dist/minty/bootstrap.min.css';
import './i18n';
import './App.css';



import ReactGA from "react-ga4";
import LetsPartner from './pages/LetsPartner';
import Affiliate from './pages/Affiliate';
import AboutUs from './pages/AboutUs';
ReactGA.initialize("TBHT1X4QEV");


function App() {
  const { i18n } = useTranslation(); // Destructure i18n
  return (

    <Routes>
      {/* Catch-all route for language-specific paths */}
      <Route path="/terms-of-use" element={<Termsofuse />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />

      <Route path="/:lang/*" element={<MainLayout />} />
      {/* Redirect root to the default language */}
      <Route path="/" element={<Navigate to={`/${i18n.language}`} />} />
      {/* Redirect unmatched paths */}
      <Route path="*" element={<Navigate to={`/${i18n.language}`} />} />
    </Routes>

  );
}

function MainLayout() {
  const { lang } = useParams();
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang);
    } else if (!lang) {
      navigate(`/${i18n.language}`);
    }
  }, [lang, i18n, navigate]);

  return (
    <>
      <Navigation />
      <div className="container-fluid">
        <main role="main">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="partnership-opportunities" element={<LetsPartner />} />
            <Route path="affiliate" element={<Affiliate />} />
            <Route path="findpets" element={<FindPets />} />
            <Route path="pet-advisor" element={<PetAdvisor />} />
            <Route path="breed-detector" element={<BreedDetector />} />
            <Route path="videos" element={<VideoPage />} />
            <Route path=":slug/:id" element={<PetDetail />} />
            <Route path="how-to-cancel-subscription-android-and-ios" element={<CancelSubscription />} />
            <Route path="about-us" element={<AboutUs />} />
            <Route path="terms-of-use" element={<Termsofuse />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/" element={<Navigate to={`/${i18n.language}`} />} />
            <Route path="*" element={<Navigate to={`/${i18n.language}`} />} />
            <Route path="*" element={<Navigate to={`/${lang}`} />} />
          </Routes>
        </main>
      </div>
      <Footer /> {/* Include the Footer here */}
    </>
  );
}

export default App;