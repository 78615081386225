import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LetsPartner = () => {
    const { t } = useTranslation();

    return (
        <Container className="my-5">
            {/* Meta Tags for SEO */}
            <Helmet>
                <title>{t('partnership-opportunities.meta_title_affilate')}</title>
                <meta name="description" content={t('partnership-opportunities.meta_description_affilate')} />
                <meta name="keywords" content={t('partnership-opportunities.meta_keywords_affilate')} />
            </Helmet>

            {/* Affiliate Program Section */}
            <Row className="mb-5">
                <Col>
                    <h2>{t('partnership-opportunities.affiliatePartnerships')}</h2>
                    <p>{t('partnership-opportunities.affiliateDescription')}</p>
                    <h4>{t('partnership-opportunities.affiliateHowItWorks')}</h4>
                    <ul>
                        <li><strong>{t('partnership-opportunities.higherEarnings')}</strong></li>
                        <li><strong>{t('partnership-opportunities.createUniqueLink')}</strong></li>
                        <pre>https://apps.apple.com/app/apple-store/id1549828591?pt=581834&ct=[uniqueId]&mt=8</pre>
                        <p>{t('partnership-opportunities.exampleLink')}</p>
                        <ul>
                            <li>{t('partnership-opportunities.createUniqueLinkExample')}:</li>
                            <pre>{t('partnership-opportunities.affiliateLink')}</pre>
                        </ul>
                    </ul>

                    {/* Example Calculation */}
                    <h4>{t('partnership-opportunities.earningsExampleTitle')}</h4>
                    <p>
                        {t('partnership-opportunities.earningsExample')}
                    </p>

                    {/* Refunds Clause */}
                    <h4>{t('partnership-opportunities.refundClauseTitle')}</h4>
                    <p>
                        {t('partnership-opportunities.refundClause')}
                    </p>

                    {/* Payment Terms */}
                    <h4>{t('partnership-opportunities.paymentTermsTitle')}</h4>
                    <p>
                        {t('partnership-opportunities.paymentTerms')}
                    </p>

                    <p>{t('partnership-opportunities.emailUs')}</p>
                    <p><strong>{t('partnership-opportunities.onlyApprovedAffiliates')}</strong></p>
                </Col>
            </Row>


        </Container>
    );
};

export default LetsPartner;
