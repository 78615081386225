import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';



const BreedDetector = () => {

    const [selectedImage, setSelectedImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const { t } = useTranslation();




    return (

        <>
            <Helmet>
                <title>{t('breed.meta_title')}</title>
                <meta name="description" content={t('breed.meta_description')} />
                <meta name="keywords" content={t('breed.meta_keywords')} />
            </Helmet>

            <Container>
                <Row>
                    <Col md={12} className="p-4">
                        <h2 className="my-4">{t('breed.h1')} </h2>
                        <p className="text-muted">{t('breed.description')}</p>

                        <hr />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default BreedDetector;
