import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const LanguageSwitcher = () => {
    const { i18n } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const currentLang = i18n.language;  // Get current language

    const changeLanguage = (event) => {
        const newLang = event.target.value;
        if (newLang !== currentLang) {
            // Replace the language segment in the URL
            const newPath = location.pathname.replace(`/${currentLang || 'en'}`, `/${newLang}`);
            i18n.changeLanguage(newLang);  // Change language in i18n
            navigate(newPath);  // Navigate to the updated path
        }
    };

    return (
        <div>
            <select value={currentLang} onChange={changeLanguage} style={{ padding: '5px' }}>
                <option value="en">English</option>
                <option value="de">Deutsch</option>
                <option value="es">Español</option>
                <option value="fr">Français</option>
                <option value="ar">العربية</option>
                <option value="nl">Nederlands</option>
                <option value="it">Italiano</option>
                <option value="pt">Português</option>
                <option value="no">Norsk </option>
                <option value="pl">Polski</option>
                <option value="sv">Svenska</option>
                <option value="da">Dansk</option>
                <option value="zh">中文</option>
            </select>
        </div>
    );
};

export default LanguageSwitcher;
