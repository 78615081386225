import React, { useEffect } from 'react';
import { Container, Row, } from 'react-bootstrap';


import { useTranslation } from 'react-i18next';
function CancelSubscription() {

    const { i18n, t } = useTranslation();


    useEffect(() => {
        if (!i18n.language) {
            i18n.changeLanguage('en'); // Set default language to English
        }
    }, [i18n]);


    return (
        <Container>
            <Row>
                <Container>


                    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
                        <h1> Cancel Subscription</h1>
                        <p>
                            If you cancel your subscription while still in the trial period, please
                            note the following:
                        </p>
                        <ul>
                            <li>
                                <strong>All your ads and messages will be deleted.</strong>
                            </li>
                            <li>
                                <strong>
                                    You will no longer be eligible for another free trial in the future.
                                </strong>
                            </li>
                        </ul>

                        <hr style={{ margin: "20px 0" }} />

                        <h2>How to Cancel Your Subscription</h2>

                        <div>
                            <h3>iOS Devices</h3>
                            <p>
                                To cancel a subscription on your iPhone, iPad, or iPod Touch, follow
                                the official guide provided by Apple:{" "}
                                <a
                                    href="https://support.apple.com/en-gb/118428"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#007BFF" }}
                                >
                                    How to cancel subscriptions on your Apple device
                                </a>
                            </p>
                        </div>

                        <div>
                            <h3>Android Devices</h3>
                            <p>
                                You can cancel, pause, or change your subscription through Google
                                Play. Ensure you have the latest version of the Google Play app and
                                follow these steps:
                            </p>
                            <ol>
                                <li>Open the Google Play Store.</li>
                                <li>Tap on your profile icon and go to <strong>Account</strong>.</li>
                                <li>Select <strong>My Subscriptions</strong>.</li>
                                <li>
                                    Choose the subscription you want to cancel and tap{" "}
                                    <strong>Cancel Subscription</strong>.
                                </li>
                            </ol>
                            <p>
                                For more details, refer to the official Google Play support page:{" "}
                                <a
                                    href="https://support.google.com/googleplay/answer/7018481"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: "#007BFF" }}
                                >
                                    Manage your subscriptions on Google Play
                                </a>
                            </p>
                        </div>
                    </div>


                </Container>
            </Row >
        </Container >
    );
}

export default CancelSubscription;
